<template>
  <ValidationObserver v-slot="{ handleSubmit }" tag="div" ref="form" class="form">
    <v-form @submit.prevent="handleSubmit(submitHandler)">
      <div class="overflow-auto inputs-wrapper">
        <div v-for="(member, idx) in members"
             :key="idx" class="d-flex space-x-2.5 mb-5">
          <ValidationProvider class="email-field" mode="eager" tag="div" :customMessages="customMessages(member)"
                              name="email" :vid="`email[${idx}]`" :rules="validateRules" v-slot="{ errors }">
            <div class="mb-2 text-body gray-100--text font-weight-semi-bold" v-if="idx === 0">E-mail</div>
            <v-combobox
              v-model="member.email"
              :items="member.searchMember?.length >= 2 ? suggestions : []"
              :search-input.sync="member.searchMember"
              @update:search-input="onSearch($event, idx)"
              persistent-hint
              hide-details
              hide-selected
              dense
              outlined
              append-icon=""
              hide-no-data
              :menu-props="{contentClass: 'ui-autocomplete-menu'}"
              :error="!!errors.length"
              placeholder="Enter member email"
              class="members-select"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>
          <div class="role-field">
            <div class="mb-2 text-body gray-100--text font-weight-semi-bold" v-if="idx === 0">Space Role</div>
            <UiSelect
              v-model="member.role"
              :items="membersRoleOptions"
              item-text="title"
              item-value="value"
              hide-details
            />
          </div>
          <div class="role-field">
            <div class="mb-2 text-body gray-100--text font-weight-semi-bold" v-if="idx === 0">Project Role</div>
            <SpaceUserRoleSelectInput
              v-if="getRolesList.length"
              v-model="member.project_roles"
              :rolesList="getRolesList"
            />
          </div>
        </div>
      </div>
      <slot name="action" :valid="formValid"/>
    </v-form>
  </ValidationObserver>
</template>

<script>
import UiSelect from "@/components/UI/UiSelect";
import {validate} from 'vee-validate'
import SpaceUserRoleSelectInput from "@/components/spaces/SpaceUserRoleSelectInput.vue";
import {mapGetters} from "vuex";

export default {
  name: "SpaceMembersForm",
  components: {
    SpaceUserRoleSelectInput,
    UiSelect,
  },
  props: {
    excludedEmails: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      formValid: false,
      members: [{
        email: '',
        role: this.$config.space.userRole.spaceMember,
        project_roles: [this.$config.project.userRole.watcher],
        searchMember: '',
      }],
      suggestions: [],
      membersRoleOptions: [
        {
          title: this.$config.space.userRoleLabels[this.$config.space.userRole.spaceAdmin],
          value: this.$config.space.userRole.spaceAdmin
        },
        {
          title: this.$config.space.userRoleLabels[this.$config.space.userRole.spaceMember],
          value: this.$config.space.userRole.spaceMember
        },
      ],
      rolesList: []
    }
  },
  computed: {
    ...mapGetters(['getRolesList']),
    currentMembersEmail() {
      return this.members.map(member => member.email)
    },
    currentMembersEmailDuplicate() {
      return this.currentMembersEmail.filter((item, index) => this.currentMembersEmail.indexOf(item) !== index)
    },
    validateRules() {
      return {
        email: true,
        excluded: [...this.excludedEmails, ...this.currentMembersEmailDuplicate]
      }
    },
    customMessages() {
      return member => {
        return {excluded: this.excludedEmails.includes(member.email) ? 'Invite already sent' : 'Email already exists'}
      }
    }
  },
  methods: {
    async asyncFilter(arr, predicate) {
      const results = await Promise.all(arr.map(predicate));

      return arr.filter((_v, index) => results[index]);
    },

    async asyncEvery(arr, predicate) {
      for (let e of arr) {
        if (!await predicate(e)) return false;
      }
      return true;
    },
    async validateFields() {
      this.formValid = await this.asyncEvery(this.members.filter(member => !!member.email), async (member) => {
        const {valid} = await validate(member.email, this.validateRules)
        return member.email.length > 0 && valid
      }) && this.currentMembersEmail.filter(email => !!email).length
    },
    async submitHandler() {
      const data = await this.asyncFilter(this.members, async (member) => {
        const {valid} = await validate(member.email, this.validateRules)
        return member.email.length > 0 && valid
      })
      this.$emit('submit', data.map(member => {
        return {
          email: member.email,
          role: member.role,
          project_roles: member.project_roles
        }
      }))
    },
    async getSuggestionMembers() {
      this.loading = true
      try {
        const {data: members} = await this.$api.spaces.getAllUsersSpaces()

        this.suggestions = members.reduce((acc, space) => {
          space.users.forEach(user => acc.push(user.email))

          return [...new Set(acc)]
        }, [])

      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async onSearch(search, idx) {
      if (search !== null) {
        if (!this.suggestions.length) {
          await this.getSuggestionMembers()
        }
        this.members[idx].email = search
        this.onInput(idx)
      }
    },
    onInput(idx) {
      if (!this.members[idx + 1]) {
        this.members.push({
          email: '',
          role: this.$config.space.userRole.spaceMember,
          project_roles: [this.$config.project.userRole.watcher],
          searchMember: '',
        })

      }
      this.validateFields()
    },
  },
  created() {
    this.getSuggestionMembers()
  }
}
</script>

<style scoped lang="scss">
.form {
  max-width: 542px;

  .email-field {
    width: 100%;
    max-width: 220px;
  }

  .role-field {
    width: 100%;
    max-width: 150px;
  }

  :deep(.members-select.v-input--is-dirty) ::placeholder {
    color: rgba(0, 0, 0, 0.38) !important;
  }

}
</style>
