<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6476_107072)">
      <path d="M6.43908 6.37484C6.60561 5.90144 6.93431 5.50225 7.36696 5.24797C7.79961 4.9937 8.30829 4.90075 8.80291 4.98559C9.29752 5.07043 9.74615 5.32758 10.0693 5.7115C10.3925 6.09542 10.5694 6.58133 10.5687 7.08317C10.5687 8.49984 8.44366 9.20817 8.44366 9.20817M8.50033 12.0415H8.50741M15.5837 8.49984C15.5837 12.4119 12.4123 15.5832 8.50033 15.5832C4.58831 15.5832 1.41699 12.4119 1.41699 8.49984C1.41699 4.58782 4.58831 1.4165 8.50033 1.4165C12.4123 1.4165 15.5837 4.58782 15.5837 8.49984Z" stroke="#81848B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_6476_107072">
        <rect width="17" height="17" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'IconHelpCircle'
}
</script>
