<template>
  <div class="relative">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      right
      allow-overflow
      content-class="user-role-select-menu"
    >

      <template v-slot:activator="{ on, attrs }">
        <UiBtn
          v-bind="attrs"
          v-on="on"
          width="100%"
          height="auto"
          min-height="28px"
          outlined
          :color="menu ? 'accent' : 'gray-30'"
          :ripple="false"
          :class="[`user-role-select-input pl-4 pr-2`, {'is-active': menu}]"
        >
          <span
            class="select-content text-left text-captions-1 gray-60--text"
          >
            {{userProjectRole}}
          </span>
          <IconChevronDown width="14" :class="['icon-transition', menu ? 'accent--text' : 'gray-80--text']"/>
        </UiBtn>
      </template>

      <v-list dense nav color="transparent" class="pt-2 pb-3">
        <v-list-item-group
          v-model="selectedRole"
          mandatory
          @change="setUserRole"
        >
          <template v-for="role in rolesTree">
            <v-list-item
              :key="`item-${role.name}`"
              :value="role.name"
              :ripple="false"
              :disabled="!canChangeOwnRole || role.name === $config.project.userRole.projectOwner"
              dark
              class="px-5 rounded"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action class="mt-2 mr-1 mb-auto">
                  <UiCheckbox
                    :input-value="active"
                    hide-details
                    color="accent"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="gray-80--text">{{ role.display_name }}</v-list-item-title>

                  <v-list
                    v-if="selectedRole === role.name && role.children.length"
                    dense
                    color="transparent"
                    class="pt-2 pb-0"
                  >
                    <v-list-item-group
                      v-model="multipleSelectedRoles"
                      mandatory
                      multiple
                      @change="setUserMultipleRole"
                    >
                      <template v-for="subRole in role.children">
                        <v-list-item
                          :key="`item-${subRole.name}`"
                          :value="subRole.name"
                          :ripple="false"
                          :disabled="!canChangeOwnRole"
                          class="px-0 rounded"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-action class="my-0 mr-1">
                              <UiCheckbox
                                :input-value="active"
                                checkbox-form="square"
                                hide-details
                                color="accent"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title class="gray-80--text">{{ subRole.display_name }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {createDataTree} from "@/utils/helpers";
import UiBtn from "@/components/UI/UiBtn";
import UiCheckbox from "@/components/UI/UiCheckbox";

export default {
  name: 'SpaceUserRoleSelectInput',
  components: {
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),

    UiBtn,
    UiCheckbox,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
  data() {
    return {
      menu: false,
      selectedRole: '',
      multipleSelectedRoles: [],
      chips: [],
    }
  },
  watch: {
    value: {
      handler() {
        const firstRole = this.getRolesList.find(role => role.name === this.value[0]);
        if (firstRole.parent_id !== null) {
          const allChildren = this.getRolesList.filter(role => role.parent_id === firstRole.parent_id);

          this.selectedRole = this.getRolesList.find(role => role.id === firstRole.parent_id).name;
          this.multipleSelectedRoles = this.value;

          if (allChildren.length === this.value.length) {
            this.chips = [this.selectedRole];
          } else {
            this.chips = this.value;
          }
        } else {
          const hasChildren = this.getRolesList.find(role => role.parent_id === firstRole.id);
          if (hasChildren !== undefined) {
            this.multipleSelectedRoles = this.getRolesList.filter(role => role.parent_id === firstRole.id).sort((a, b) => {
              return a.sort_order > b.sort_order ? 1 : -1;
            }).map(role => role.name);
          }
          this.selectedRole = this.value[0];
          this.chips = [this.selectedRole];
        }
      }, immediate: true
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getRolesList',
    ]),
    rolesTree() {
      const sort = arr => {
        arr.sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
        if (arr.children && arr.children.length) {
          sort(arr.children);
        }
      }

      const tree = [...createDataTree('id', 'parent_id', this.getRolesList)].sort((a, b) => {
        return a.sort_order > b.sort_order ? 1 : -1
      });

      tree.forEach(item => {
        if (item.children && item.children.length) {
          sort(item.children);
        }
      });
      return tree;
    },
    canChangeOwnRole() {
      // const {projectOwner} = this.$config.project.userRole;
      // if (this.user.user_id === this.getAuthId && this.value.includes(projectOwner)) {
      //   return this.getProject.team.filter(user => user.user_role.includes(projectOwner)).length > 1;
      // }
      return true;
    },

    userProjectRole() {
      return this.chips.map(role => {
        return this.getRoleByName(role).display_name
      }).join(', ')
    }
  },
  methods: {
    getRoleByName(role) {
      return this.getRolesList.find(r => r.name === role);
    },
    removeUserRole(role) {
      if (this.chips.length === 1) return;
      this.chips = this.chips.filter(r => r !== role);
      const firstRole = this.getRolesList.find(role => role.name === this.chips[0]);

      if (firstRole.parent_id !== null) {
        this.multipleSelectedRoles = this.chips;
      }

      this.$emit('input', this.chips);
    },
    setUserRole() {
      if (this.chips > 1) {
        this.multipleSelectedRoles = [];
      }
      const targetRole = this.getRolesList.find(role => role.name === this.selectedRole);
      const hasChildren = this.getRolesList.find(role => role.parent_id === targetRole.id);
      if (hasChildren !== undefined) {
        this.multipleSelectedRoles = this.getRolesList.filter(role => role.parent_id === targetRole.id).sort((a, b) => {
          return a.sort_order > b.sort_order ? 1 : -1;
        }).map(role => role.name);
      }
      this.chips = [this.selectedRole];
      this.$emit('input', [this.selectedRole]);
    },
    setUserMultipleRole() {
      const targetRole = this.getRolesList.find(role => role.name === this.selectedRole);
      const allChildren = this.getRolesList.filter(role => role.parent_id === targetRole.id);
      if (this.multipleSelectedRoles.length === allChildren.length) {
        this.chips = [this.selectedRole];
        this.$emit('input', [this.selectedRole]);
      } else {
        this.chips = this.multipleSelectedRoles;
        this.$emit('input', this.multipleSelectedRoles);
      }
    }
  },
}
</script>

<style scoped lang="scss">
:deep(.user-role-select-input) {
  padding-top: 4px !important;
  padding-bottom: 4px !important;

  .v-btn__content {
    width: 100%;
    white-space: pre-wrap;
    gap: 4px;
  }

  &.is-active svg {
    transform: rotate(-180deg);
  }

  .select-content {
    flex: 1 1 auto;
    text-align: left;
    word-break: break-word;
  }

  svg {
    flex-shrink: 0;
  }
}

.user-role-select-menu {
  box-shadow: 0 4px 31px rgb(0 0 0 / 10%);
  border: 1px solid var(--v-gray-30-base);
  background-color: var(--v-gray-0-base);
}

.v-item-group {
  .v-list-item {
    &::before {
      display: none;
    }

    &--active,
    &:hover {
      background-color: var(--v-gray-30-base);
    }
  }
}
</style>
