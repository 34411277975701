<template>
  <div v-if="loading" class="d-flex justify-center pt-16">
    <v-progress-circular
      :size="100"
      color="accent"
      indeterminate
    />
  </div>
  <div v-else>
    <div class="d-flex align-center justify-space-between mb-5">
      <div class="d-flex align-center space-x-2">
        <UISearch
          class="design-search"
          @onSearch="setSearch"
        />

        <UiFilter
          :filterData="filterData"
          offset-y
          allow-overflow
          left
          @setFilters="setFilter"
        />

        <UiSort
          mandatory1
          mandatory2
          :list="sortList1"
          :list2="sortList2"
          :value1="sortBy.sort_1"
          :value2="sortBy.sort_2"
          @setSort1="value => this.setSort('sort_1', value)"
          @setSort2="value => this.setSort('sort_2', value)"
        />
      </div>
      <UiBtn color="accent" width="144" @click="inviteMembersDialog = true" v-if="canEdit">
        Invite users
      </UiBtn>
    </div>
    <MembersTable v-if="members && members.length" @edit="onEdit" @delete="onDelete" :members="members" :space="space"/>
    <div v-else class="d-flex flex-column align-center justify-center py-10">
      <div class="mb-5 text-center">There are no members in the space yet.</div>
      <UiBtn color="accent" width="144" @click="inviteMembersDialog = true" v-if="canEdit">
        Invite users
      </UiBtn>
    </div>

    <v-dialog
      v-model="inviteMembersDialog"
      max-width="600"
    >
      <div class="gray-0 py-9 px-9 px-sm-7">
        <div class="d-flex align-center justify-space-between">
          <h5 class="text-title-2 gray-100--text">Invite new members</h5>
        </div>

        <v-divider class="mt-5 mb-8"/>
        <SpaceMembersForm v-if="inviteMembersDialog" :excluded-emails="usersEmails" class="members-form"
                          @submit="inviteMembers">
          <template #action="{valid}">
            <div class="d-flex align-center justify-end space-x-2 mt-4">
              <UiBtn width="150" @click="inviteMembersDialog = false" outlined color="accent">Cancel</UiBtn>
              <UiBtn width="150" color="accent" :disabled="!valid" :loading="dialogLoading" type="submit">Send Invites
              </UiBtn>
            </div>
          </template>
        </SpaceMembersForm>
      </div>
    </v-dialog>
    <v-dialog
      v-model="editMembersDialog"
      max-width="520"
    >
      <div class="gray-0 py-9 px-9">
        <div class="d-flex align-center justify-space-between">
          <h5 class="text-title-2 gray-100--text">Edit Member “{{ editingMember.full_name }}”</h5>
        </div>

        <v-divider class="mt-5 mb-8"/>
        <ValidationObserver v-slot="{ handleSubmit }" tag="div" ref="form" class="form" v-if="editingMember">
          <v-form @submit.prevent="handleSubmit(editMember)">
            <div class="mb-4">
              <div class="mb-2 text-body gray-100--text font-weight-light">Status</div>
              <UiSelect
                v-model="editingMember.status"
                :items="membersOptionsStatus"
                class="role-field"
                item-text="title"
                item-value="value"
                height="38"
                hide-details
              />
            </div>

            <div class="mb-4">
              <div class="mb-2 text-body gray-100--text font-weight-light">Role</div>
              <UiSelect
                v-model="editingMember.role"
                :items="membersOptionsRole"
                class="role-field"
                item-text="title"
                item-value="id"
                height="38"
                hide-details
              />
            </div>
            <div class="mb-4">
              <div class="mb-2 text-body gray-100--text font-weight-light">Project Role</div>
              <SpaceUserRoleSelectInput
                v-if="getRolesList.length"
                v-model="editingMember.project_roles"
                :rolesList="getRolesList"
                class="project-role-field"
              />
            </div>
            <!--            <div class="gray-100&#45;&#45;text">
                          <div class="mb-1 text-body  font-weight-semi-bold">Password</div>
                          <div class="text-captions-1">
                            The user will receive an email with instructions to reset their account password.
                          </div>
                          <UiBtn color="accent" width="200" class="mt-3" outlined>Reset Password</UiBtn>
                        </div>-->

            <div class="d-flex align-center justify-end space-x-2 mt-8">
              <UiBtn width="150" @click="editMembersDialog = false" outlined color="accent">Cancel</UiBtn>
              <UiBtn width="150" color="accent" type="submit" :loading="dialogLoading">Save Changes</UiBtn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import MembersTable from "@/views/spaces/edit/MembersTable";
import UiFilter from "@/components/UI/UiFilter";
import UiSort from "@/components/UI/UiSort";
import UISearch from "@/components/UI/UiSearch";
import UiBtn from "@/components/UI/UiBtn";
import SpaceMembersForm from "@/components/spaces/SpaceMembersForm";
import UiSelect from "@/components/UI/UiSelect";
import SpaceUserRoleSelectInput from "@/components/spaces/SpaceUserRoleSelectInput.vue";
import {mapGetters} from "vuex";

export default {
  name: "SpaceAddMembersView",
  components: {SpaceUserRoleSelectInput, UiSelect, SpaceMembersForm, UiBtn, UISearch, UiSort, UiFilter, MembersTable},
  props: {
    space: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialogLoading: false,
      members: [],
      inviteMembersDialog: false,
      editMembersDialog: false,
      sortList1: [
        {name: 'Name', slug: 'user_first_name'},
        {name: 'Last active', slug: 'created_at'},
      ],
      sortList2: [
        {name: 'Ascending', slug: 'asc'},
        {name: 'Descending', slug: 'desc'}
      ],
      editingMember: {
        id: null,
        full_name: '',
        status: null,
        role: null,
        project_roles: []
      },
      membersOptionsRole: [
        {
          title: this.$config.space.userRoleLabels[this.$config.space.userRole.spaceAdmin],
          id: this.$config.space.userRole.spaceAdmin
        },
        {
          title: this.$config.space.userRoleLabels[this.$config.space.userRole.spaceMember],
          id: this.$config.space.userRole.spaceMember
        },
      ],
      membersOptionsStatus: [
        {
          title: this.$config.space.userStatusLabels[this.$config.space.userStatuses.active],
          value: this.$config.space.userStatuses.active
        },
        {
          title: this.$config.space.userStatusLabels[this.$config.space.userStatuses.inactive],
          value: this.$config.space.userStatuses.inactive
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getRolesList',
    ]),
    usersEmails() {
      return this.members.map(member => member.user.email)
    },
    filterData() {
      return [
        {
          type: 'status',
          title: 'Status',
          list: [
            {
              title: this.$config.space.userStatusLabels[this.$config.space.userStatuses.active],
              id: this.$config.space.userStatuses.active,
            },
            {
              title: this.$config.space.userStatusLabels[this.$config.space.userStatuses.inactive],
              id: this.$config.space.userStatuses.inactive
            },
          ],
          isMultiple: true,
          activeFilters: this.$route.query.status ? this.$route.query.status.split(',').map(i => +i) : [],
        },
        {
          type: 'role.name',
          title: 'Role',
          list: [
            {
              title: this.$config.space.userRoleLabels[this.$config.space.userRole.spaceAdmin],
              id: this.$config.space.userRole.spaceAdmin
            },
            {
              title: this.$config.space.userRoleLabels[this.$config.space.userRole.spaceMember],
              id: this.$config.space.userRole.spaceMember
            },
          ],
          isMultiple: true,
          activeFilters: this.$route.query['role.name'] ? this.$route.query['role.name'].split(',').map(i => i) : [],
        }]
    },
    sortBy() {
      return {
        sort_1: this.sortList1.find(sort => sort.slug === this.$route.query.sort_1) || this.sortList1[0],
        sort_2: this.sortList2.find(sort => sort.slug === this.$route.query.sort_2) || this.sortList2[0],
      }
    },
    filter() {
      const filter = {}
      if (this.$route.query.search) {
        filter['filter[search]'] = this.$route.query.search
      }

      this.filterData.forEach(data => {
        if (data.activeFilters && data.activeFilters.length) {
          filter[`filter[${data.type}]`] = data.activeFilters.join(',')
        }
      })

      if (this.sortBy.sort_2.slug === 'asc') {
        filter['sort'] = this.sortBy.sort_1.slug
      } else {
        filter['sort'] = `-${this.sortBy.sort_1.slug}`
      }

      return filter
    },
    canEdit() {
      return this.space.currentSpaceUser.user_role.name === this.$config.space.userRole.spaceAdmin
    }
  },
  methods: {
    async setSort(key, value) {
      this.$router.$updateQueryParams({[key]: value.slug});
      await this.getMembers()
    },
    async setSearch(searchVal) {
      this.$router.$updateQueryParams({search: searchVal});

      await this.getMembers();
    },
    async getMembers() {
      this.loading = true
      try {
        const {data: members} = await this.$api.spaces.userList(this.$route.params.id, this.filter)

        this.members = members
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async setFilter(filterData = null) {
      const filters = {}
      filterData.forEach(el => {
        if (Array.isArray(el.activeFilters)) {
          filters[el.type] = el.activeFilters.filter(i => i)
        } else {
          if (el.activeFilters) {
            filters[el.type] = [el.activeFilters]
          } else {
            filters[el.type] = []
          }
        }
      })
      this.$router.$updateQueryParams(filters);

      await this.getMembers()
    },
    onEdit(member) {
      if (!member) return
      const firstName = member.user.first_name || ''
      const lastName = member.user.last_name || ''
      const full_name = `${firstName} ${lastName}`.trim()
      this.editingMember = {
        id: member.user.id,
        full_name,
        status: member.status,
        role: member.user_role.name,
        project_roles: member.project_roles.map(role => {
          return role.name
        })
      }
      this.editMembersDialog = true
    },
    async inviteMembers(data) {
      this.dialogLoading = true
      try {
        await this.$api.spaces.userCreate(this.space.id, {users: data})
        this.$toast.open({
          message: `Members invited to space!`,
          type: 'success',
          position: 'top-right'
        });
        this.inviteMembersDialog = false
        await this.getMembers()
      } catch (e) {
        console.error(e)
      } finally {
        this.dialogLoading = false
      }
    },
    async editMember() {
      this.dialogLoading = true
      try {
        await this.$api.spaces.userUpdate(this.space.id, this.editingMember.id, {
          role: this.editingMember.role,
          status: this.editingMember.status,
          project_roles: this.editingMember.project_roles
        })
        this.$toast.open({
          message: `Success!`,
          type: 'success',
          position: 'top-right'
        });
        this.editingMember = {
          id: null,
          full_name: '',
          status: null,
          role: null,
          project_roles: []
        }
        this.editMembersDialog = false
        await this.getMembers()
      } catch (e) {
        console.error(e)
      } finally {
        this.dialogLoading = false
      }
    },
    onDelete(member) {
      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to delete this member?',
          confirmBtnText: 'Delete',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');
            try {
              await this.$api.spaces.userDelete(this.space.id, member.user.id)
              this.$toast.open({
                message: 'Member deleted successfully',
                type: 'info',
                position: 'top-right'
              });
              await this.getMembers()
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
    }
  },
  created() {
    this.getMembers()
  }
}
</script>

<style scoped lang="scss">
.members-form {
  :deep(.inputs-wrapper) {
    height: 308px;
    max-height: 308px;
  }
}

:deep(.project-role-field){
  .user-role-select-input{
    min-height: 38px !important;
  }
}
</style>
